@import "@fontsource/lato";

$blue_color: #575FCC;
$green_color: #8fd8b5ff;
$yellow_color: #FABE47;
$orange_color: #ff7104ff;
$red_color: #e02020ff;
$beige_color: #fff8f3ff;
$white_color: white;

$black_color: #4F4E4E;
$grey_color: #595959ff;

body {
  margin: 0;
  font-family: "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white
}

p {
  font-size: 24px;
  font-weight: 500;
  text-align: justify;
}

h1 {
  font-size: 38px;
  font-weight: 900;
}

.page {
  padding-left: 2.4rem;
    padding-right: 2.4rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
  .main-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem ;
    flex-wrap: wrap;
    z-index: 1000;
    .picture {
        max-width: 500px;
        img {
            height: auto;
            width: 100%;
        }
    }
    .info {
        max-width: 500px;
  
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
  } 
}



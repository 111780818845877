@import '../../index.scss';

.nuevas-funcionalidades-light-page {
    color: black;
    .main-content {
        .balls {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 4rem;
            //width: 100%;
            max-width: 500px;
            gap:1rem;
            padding: 1rem;
            .ball {
                max-width: 3.5rem;
                max-height: 3.5rem;
                border-radius: 50%;
            }

            .blue {
                background-color: $blue_color;
            }
            .green {
                background-color: $green_color;
            }
            .yellow {
                background-color: $yellow_color;
            }
            .orange {
                background-color: $orange_color;
            }
            .red {
                background-color: $red_color;
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: black;
        z-index: 1;
        
        .footer-contents {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            margin: 1rem;
            margin-bottom: 1rem;

            .contact {
                color: white !important;
                               
                &-title {
                    font-size: 16px; 
                    margin-bottom: 0;
                }
                &-email {
                    font-size: 12px;    
                    margin-top: 0.25rem;
                    margin-bottom: 1.5rem;
                }
            } 

            .buttons {
                display: flex;
                justify-content: space-around;
                align-items: center;
                
                gap: 1rem;

                button {
                    border: 0.15rem solid white;
                    background-color: black;
                    color: white;
                    padding: 0.5rem;
                }
            }
        }
    }
}
@import '../../index.scss';

.programacion-page {
  .curved-div {
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;    
    }
 
}


@import '../../index.scss';

.reacciones-page {
    .main-content {
        .picture {
            max-width: 500px;
        }
    }
    .lines {
        position: absolute;
        bottom: 5%;
        right: -25%;
        width: 60rem;
        z-index: -1;
        transform: rotate(-45deg);
        @media (max-width: 800px) {
            right: -50%;
        }
        @media (min-width: 1200px) {
            right: -15%;
        }
        .solid-line {
            margin-bottom: 2rem;
            width: 100%;
            height: 1rem;
            background-color: #739ef1ff;
        }
        .border-only-line {
            margin-bottom: 2rem;
            width: 100%;
            height: 0.75rem;
            border: 0.25rem solid #739ef1ff;
 
        }
    }

}
@import '../../index.scss';

.statistics-page {

	.big-spot {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 40%;
		max-width: 700px;
		min-width: 200px;
		height: 76%;
	
		z-index: -1;
	
		-webkit-border-radius: 50%/60% 60% 40% 40%;
		-moz-border-radius: 50%/60% 60% 40% 40%;
		border-radius:   90% 0% 0% 40%/50%;
		  background-color: $green_color;  
	}
	
	h1, p {
		color: black;
	  }
}

@import '../../index.scss';

.welcome-page {
    flex-direction: column;

    .subtitle {
        font-size: 24px;
        font-weight: 600;
        text-align: justify;
    }

    .logo {
        max-width: 500px;
        width: 36%;
        min-width: 250px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .app-store-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        a {
            max-height: 60px;
            min-height: 30px;
            height: 80%;
        }
        img {
            width: auto;
            height: 100%;
        }
    }
}

.modal {
    position: absolute;
    top: -25%;
    left: 50%;
    transform: translate(-50%, 50%);
    
    height: 60%;
    width: 80%;


    background-color: white;
    border-radius: 2rem;

    padding: 1.5rem;
    z-index: 2000;
    
    .modal-contents {
        height: 100%;
        overflow: scroll;
        white-space: pre-line;

        p {
            font-size: 16px;
        }
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 20px;
        }
    }

    .button {
        border: 2px solid #595959ff;
        background-color: white;
        color:#595959ff;
        
        height: 20px;
        width: fit-content;

        text-align: center;
        font-weight: 800;
    }
}